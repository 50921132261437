/**
 * Use the IntersectionObserver API to animate elements when they are in the viewport.
 */
export const useIntersectionObserver = (classname = '.fade-animate', options = {}) => {
	let observer = null;

	const loadAnimations = () => {
		const fadeElements = document.querySelectorAll(classname);
		const defaultOptions = {
			root: null,
			rootMargin: '0px',
			threshold: 0.1,
		};
		options = { ...defaultOptions, ...options };

		const callbacks = (entries) => {
			entries.forEach((entry) => {
				if (entry.isIntersecting) {
					entry.target.classList.add('active');
				}
			});
		};

		observer = new IntersectionObserver(callbacks, options);
		fadeElements.forEach((element) => {
			observer?.observe(element);
		});
	};

	onMounted(() => {
		loadAnimations();

		window.addEventListener('scroll', loadAnimations);
	});

	onBeforeUpdate(() => {
		observer?.disconnect();
		loadAnimations();

		window.removeEventListener('scroll', loadAnimations);
		window.addEventListener('scroll', loadAnimations);
	});

	onBeforeUnmount(() => {
		observer?.disconnect();

		window.removeEventListener('scroll', loadAnimations);
	});
};
